<template>
  <div>
    <Navbar />
    <div
      class="careerHeader"
      :style="{
        backgroundImage: `url('${server_url}/${career.image}')`,
      }"
    ></div>

    <div class="vacancyContainer containerALL">
      <div>
        <div>
          <div>
            <h4
              style="font-family: mtavruliBOLD; text-transform: uppercase"
              class="purple pt-4 activeVacancyTitle"
            >
              {{ $t("vacancyElements.activeVacancy") }}
            </h4>
          </div>
          <div class="row">
            <div class="searchBox2 col-12 col-sm-6 col-md-4 col-lg-2">
              <select
                style="text-transform: uppercase"
                v-model="category"
                class="form-control blackColor seacrh mt-3 mb-3 pr-0"
              >
                <option
                  style="text-transform: uppercase"
                  :value="{
                    _id: 0,
                    category: 'category',
                  }"
                >
                  {{ $t("vacancyElements.all") }}
                </option>

                <option
                  style="text-transform: uppercase"
                  v-for="item in categories"
                  :key="item._id"
                  :value="item"
                >
                  <span
                    style="text-transform: uppercase"
                    class="dejavuBOLD"
                    v-if="lang == 'ka'"
                    v-html="item.titleKA"
                  >
                    {{ item.titleKA }}</span
                  >
                  <span
                    class="mtavruliBOLD"
                    style="text-transform: uppercase"
                    v-else
                    v-html="item.titleEN"
                  >
                    {{ item.titleEN }}</span
                  >
                </option>
              </select>
            </div>
            <div class="searchBox2 col-12 col-sm-6 col-md-4 col-lg-2">
              <select
                style="text-transform: uppercase"
                v-model="location"
                class="form-control blackColor seacrh mt-3 mb-3 pr-0"
              >
                <option
                  style="text-transform: uppercase"
                  :value="{
                    _id: 0,
                    category: 'location',
                  }"
                >
                  {{ $t("vacancyElements.all") }}
                </option>

                <option
                  style="text-transform: uppercase"
                  v-for="item in locations"
                  :key="item._id"
                  :value="item"
                >
                  <span
                    v-html="item.titleKA"
                    style="text-transform: uppercase"
                    v-if="lang == 'ka'"
                  >
                    {{ item.titleKA }}</span
                  >
                  <span
                    v-html="item.titleEN"
                    style="text-transform: uppercase"
                    v-else
                  >
                    {{ item.titleEN }}</span
                  >
                </option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="!isLoading" class="accordion" role="tablist">
          <div v-for="(item, index) in items" :key="item._id">
            <b-card no-body class="mb-3">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  :v-b-toggle="`accordion-${index}`"
                  variant="tab"
                >
                  <div
                    @click="switchVisibeAccordion(index)"
                    class="vacancyList row text-left d-flex align-items-center"
                  >
                    <div
                      class="
                        vacancyHeader
                        pl-0
                        ml-0
                        d-flex
                        pl-3
                        col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9
                      "
                    >
                      <div class="mt-3">
                        <img
                          style="width: 20px"
                          src="../../../../assets/icons/arrowList.png"
                        />
                      </div>
                      <div
                        class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 pl-0"
                      >
                        <div
                          style="font-size: 14px; text-transform: uppercase"
                          class="blackColor"
                        >
                          {{ $t("vacancyElements.position") }}
                        </div>
                        <div class="blackColor">
                          <span
                            style="
                              text-transform: uppercase;
                              font-family: dejavuBOLD;
                            "
                            v-if="lang == 'ka'"
                            v-html="item.category.titleKA"
                          >
                            {{ item.category.titleKA }}</span
                          >
                          <span
                            style="
                              text-transform: uppercase;
                              font-family: dejavuBOLD;
                            "
                            v-html="item.category.titleEN"
                            v-else
                          >
                            {{ item.category.titleEN }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 pl-0"
                      >
                        <div style="font-size: 14px; text-transform: uppercase">
                          {{ $t("vacancyElements.location") }}
                        </div>
                        <div class="blackColor">
                          <span
                            style="
                              text-transform: uppercase;
                              font-family: dejavuBOLD;
                            "
                            v-if="lang == 'ka'"
                            v-html="item.location.titleKA"
                          >
                            {{ item.location.titleKA }}</span
                          >
                          <span
                            style="
                              text-transform: uppercase;
                              font-family: dejavuBOLD;
                            "
                            v-else
                            v-html="item.location.titleEN"
                          >
                            {{ item.location.titleEN }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="
                        col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3
                        listButtons
                        pl-0
                        pr-0
                        pt-2
                        pb-2
                      "
                    >
                      <div class="col pr-0">
                        <ModalComponent :item="item" />
                        <div class="p-0 m-0">
                          <p class="align-middle pt-1 pb-0 mb-0">
                            {{ $t("vacancyElements.or") }}
                          </p>
                        </div>
                        <div>
                          <router-link to="/vacancyForm"
                            ><button
                              style="width: 200px; text-transform: uppercase"
                              type="button"
                              class="btn btn-primary1 pt-1 pb-1"
                            >
                              {{ $t("vacancyElements.fillForm") }}
                            </button></router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </b-button>
              </b-card-header>
              <b-collapse
                :id="`accordion-${index}`"
                :visible="index === visibleIndex ? true : false"
                :accordion="`menu-accordion${index}`"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text style="text-transform: uppercase">
                    {{ $t("vacancyElements.startDate") }}
                    <div v-if="lang == 'ka'" class="mtavruliBOLD blackColor">
                      {{ item.startDateKA }} - {{ item.endDateKA }}
                    </div>
                    <div v-else class="mtavruliBOLD blackColor">
                      {{ item.startDateKA }} - {{ item.endDateKA }}
                    </div>
                  </b-card-text>

                  <b-card-text
                    style="text-transform: uppercase"
                    v-if="lang == 'ka'"
                    v-html="item.descriptionKA"
                  >
                    {{ item.descriptionKA }}
                  </b-card-text>
                  <b-card-text
                    v-html="item.descriptionEN"
                    style="text-transform: uppercase"
                    v-else
                  >
                    {{ item.descriptionEN }}
                  </b-card-text>

                  <div class="mt-3 newsArch">
                    <router-link :to="'/career' + '/' + item.slug">
                      <button
                        style="font-family: mtavruli"
                        type="button"
                        class="btn btn-link pl-0"
                      >
                        <p style="text-transform: uppercase" class="btn-link-p">
                          {{ $t("front.more") }}
                        </p>
                      </button>
                    </router-link>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>

          <div
            class="
              d-flex
              col-12
              align-itsmes-cenetr
              mt-5
              justify-content-center
            "
          >
            <b-pagination
              v-if="items.length > 0"
              v-model="currentPage"
              :total-rows="paginationLength"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
        <div
          class="noVacancy"
          style="display: flex; justify-content: center"
          v-if="!isLoading && items.length === 0"
        >
          <div class="align-items-center noneVacancy">
            <h4 style="text-transform: uppercase" class="purple mtavruliBOLD">
              {{ $t("vacancyElements.dontHaveVacancy") }}
            </h4>
            <p>
              {{ $t("vacancyElements.willLookResume") }}
            </p>
            <div class="col">
              <div>
                <button
                  style="width: 200px"
                  type="submit"
                  class="btn btn-primary pt-1 pb-1"
                  @click="showModal()"
                >
                  <form style="text-transform: uppercase">
                    <input
                      hidden
                      id="fileUpload"
                      class="ml-0 mr-0 uploadTitle"
                      placeholder="file"
                      style="
                        width: 100%;
                        color: white;
                        text-transform: uppercase;
                      "
                      type="file"
                      onchange="this.form.submit()"
                    />{{ $t("vacancyElements.uploadResume") }}
                  </form>
                </button>
                <b-modal
                  v-model="modalShow"
                  ok-only
                  ok-variant="secondary"
                  :ok-title="$t('front.cancel')"
                >
                  <ResumeUpload :modalShow="showModal" />
                </b-modal>
              </div>
              <div class="p-0 m-0">
                <p class="align-middle pt-1 pb-0 mb-0">
                  {{ $t("vacancyElements.or") }}
                </p>
              </div>
              <div>
                <router-link to="/vacancyForm"
                  ><button
                    style="width: 200px; text-transform: uppercase"
                    type="button"
                    class="btn btn-primary1 pt-1 pb-1"
                  >
                    {{ $t("vacancyElements.fillForm") }}
                  </button></router-link
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="isLoading">
          <div class="row g-2 mb-4">
            <div
              class="col col-12 mt-4 col-12"
              v-for="item in [1, 2]"
              :key="item"
            >
              <b-skeleton-img
                no-aspect
                height="10rem"
                type="input"
              ></b-skeleton-img>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import env from "../../../../env.json";
import axios from "axios";
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";
import ResumeUpload from "./ResumeUpload.vue";

import ModalComponent from "./ModalComponent.vue";

export default {
  components: {
    Navbar,
    Footer,
    ResumeUpload,
    ModalComponent,
  },

  data() {
    return {
      modalShow: false,
      category: { _id: 0, category: "category" },
      location: { _id: 0, category: "location" },
      items: [],
      currentPage: 1,
      perPage: 6,
      paginationLength: 0,
      isLoading: true,
      lang: "ka",
      career: {},

      selectedPlace: "",
      selectedCategory: "",
      server_url: env.server_url,

      visibleIndex: -1,

      categories: null,
      locations: null,
    };
  },
  methods: {
    switchVisibeAccordion(index) {
      if (this.visibleIndex === index) {
        this.visibleIndex = -1;
      } else {
        this.visibleIndex = index;
      }
    },
    showModal: function () {
      this.modalShow = !this.modalShow;
    },
    // chooseFiles: function () {
    //   document.getElementById("fileUpload").click();
    // },
    filterVacancy: function (filterBy) {
      const FILTER_BY_PAGINATION = "filter-by-pagination";
      const GET_ALL_PAGINATION = "pagination";

      let categoryNow = this.category;
      let locationNow = this.location;

      if (filterBy.category === "category") {
        categoryNow = filterBy;
      }
      if (filterBy.category === "location") {
        locationNow = filterBy;
      }

      let ifChecker = false;

      let reqParams;

      if (categoryNow._id != 0 && locationNow._id != 0) {
        reqParams = {
          type: FILTER_BY_PAGINATION,
          filter: `${categoryNow._id}&${locationNow._id}`,
          by: "category&location",
        };
        ifChecker = true;
      }

      if (!ifChecker && categoryNow._id == 0 && locationNow._id == 0) {
        reqParams = { type: GET_ALL_PAGINATION, filter: "null", by: "null" };
        ifChecker = true;
      }

      if (!ifChecker && categoryNow._id != 0 && locationNow._id == 0) {
        reqParams = {
          type: FILTER_BY_PAGINATION,
          filter: filterBy._id,
          by: filterBy.category,
        };
        ifChecker = true;
      }

      if (!ifChecker && categoryNow._id == 0 && locationNow._id != 0) {
        reqParams = {
          type: FILTER_BY_PAGINATION,
          filter: filterBy._id,
          by: filterBy.category,
        };
        ifChecker = true;
      }

      const reqUrl = `${env.host}/get/${reqParams.type}/career/${reqParams.filter}/1/4/${reqParams.by}`;

      this.isLoading = true;
      axios
        .get(reqUrl)
        .then((result) => {
          this.items = [...result.data.item];
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    axios.get(`${env.host}/get/all/career/categories/admin`).then((result) => {
      this.categories = result.data.item.category;
      this.locations = result.data.item.location;

      console.log(result);

      axios
        .get(`${env.host}/get/pagination/career/null/1/4/null`)
        .then((result) => {
          this.items = [...result.data.item];
          this.paginationLength = result.data.total_page;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));

      if (localStorage.getItem("lang")) {
        this.lang = localStorage.getItem("lang");
      }
    });

    axios
      .get(`${env.host}/get/informations/careerinfo`)
      .then((result) => {
        this.career = result.data.item;
        this.isLoading = false;
        console.log(result.data.item.image, "careerinfo");
      })
      .catch((err) => console.log(err));
  },
  watch: {
    currentPage: function (newValue) {
      this.isLoading = true;
      axios
        .get(
          `${env.host}/get/all/career/id/${newValue}/${this.perPage}/location`
        )
        .then((result) => {
          if (result.data.item) {
            this.items = [...result.data.item];
            this.paginationLength = result.data.total_page;
            this.isLoading = false;
          }
        });
    },
    category: function (newValue) {
      this.filterVacancy(newValue);
    },
    location: function (newValue) {
      this.filterVacancy(newValue);
    },
  },

  computed: {
    computed_items: function () {
      let filterPlace = this.selectedPlace,
        filterCategory = this.selectedCategory;
      return this.items.filter(function (item) {
        let filtered = true;
        if (filterPlace && filterPlace.length > 0) {
          filtered = item.Place == filterPlace;
        }
        if (filtered) {
          if (filterCategory && filterCategory.length > 0) {
            filtered = item.Category == filterCategory;
          }
        }
        return filtered;
      });
    },
  },
};
</script>

<style scoped>
.activeVacancyTitle {
  color: #7e5493;
}
/* 
* {
  color: #666666;
} */
.form-control {
  border-radius: 0px;
}
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0px;
  outline: 0;
  margin-top: 250px !important;
}
.uploadTitle {
  color: white;
}
.careerHeader {
  height: 20rem;
  max-width: 1400px;
  margin: auto;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.vacancyContainer {
  width: 100%;
  min-height: calc(100vh - 190px);
  max-width: 1400px;
  margin: auto;
}
.noVacancy {
  max-width: 1400px;

  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}

@media all and (max-width: 1500px) {
  .vacancyContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .noVacancy {
    margin-bottom: 80px;
  }
}

@media all and (max-width: 991px) {
  .careerHeader {
    height: 18rem;
  }
}

@media all and (max-width: 575px) {
  .careerHeader {
    height: 14rem;
  }
}

/*  vacancy style */
::v-deep .btn-tab {
  background-color: #f4f2f3;
  border-color: #f4f2f3;
  color: #4d4d4c;
}
::v-deep .btn-tab:not(:disabled):not(.disabled).active,
.btn-tab:not(:disabled):not(.disabled):active,
.show > .btn-tab.dropdown-toggle {
  color: #4d4d4c;
  background-color: #f4f2f3;
  border-color: #f4f2f3;
}
::v-deep .card {
  border: none !important;
}
::v-deep .btn-tab:not(:disabled):not(.disabled).active:focus,
.btn-tab:not(:disabled):not(.disabled):active:focus,
.show > .btn-tab.dropdown-toggle:focus {
  box-shadow: none;
}
::v-deep .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
::v-deep .btn {
  border-radius: 0px;
}
.btn-link:hover {
  /* color: #0056b3; */
  text-decoration: none !important;
}
::v-deep .card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  /* border-bottom: 0px; */
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}
::v-deep .btn-tab.focus,
.btn-tab:focus {
  background-color: white;
  border-color: white;
}
::v-deep .btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled):active {
  background-color: none;
  border-color: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e54939f;
  border-color: #7e54939f;
}
.tabsIcons {
  width: 50px;
}
.btn-primary {
  border-radius: 0px;
  background-color: #7e5493;
  /* border-color: #7e5493; */
  padding-left: 20px;
  margin: auto;
  border: none;
}
.btnArchive {
  border: none;
  color: #7e5493;
  background-color: none;
  font-family: mtavruli;
  /* border-bottom: 1.5px solid #c6bec9; */
}
.btn-primary1 {
  background-color: #a4a8ab;
  border-radius: 0px;
  color: white;
  padding-left: 20px;
  margin: auto;
  border: none;
}
.listButtons {
  display: flex;
  justify-content: flex-end;
  text-align: center;
}
.btn-link-p {
  color: #7e5493;
  border-bottom: 1px solid #7e5493;
}
::v-deep .page-link {
  color: #bdbdbd;
}
::v-deep .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7e5493 !important;
  border-color: #7e5493 !important;
}
::v-deep .page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem #7e54935b !important;
}

/*  */
/* vacancy header */
form-control {
  border-radius: 0px;
}
.form-control:focus {
  border-color: #7e5493;
  box-shadow: none;
}
</style>
