<template>
  <div>
    <button
      style="width: 200px; text-transform: uppercase"
      type="submit"
      class="btn btn-primary pt-1 pb-1"
      @click="showModal()"
    >
      <form style="text-transform: uppercase">
        <input
          hidden
          id="fileUpload"
          class="ml-0 mr-0 white"
          placeholder="file"
          style="width: 100%; color: white"
          type="file"
          onchange="this.form.submit()"
        />
        {{ $t("vacancyElements.uploadResume") }}
      </form>
    </button>
    <b-modal
      :title="lang == 'ka' ? item.category.titleKA : item.category.titleEN"
      v-model="modalShow"
      ok-only
      ok-variant="secondary"
      :ok-title="$t('front.cancel')"
    >
      <ResumeUploadTitle :modalShow="showModal" :item="item.category.titleKA" />
    </b-modal>
  </div>
</template>

<script>
import ResumeUploadTitle from "./ResumeUploadTitle.vue";

export default {
  name: "ModalComponent",
  components: {
    ResumeUploadTitle,
  },
  props: ["item"],
  data() {
    return {
      lang: "ka",
      modalShow: false,
    };
  },
  methods: {
    showModal: function () {
      this.modalShow = !this.modalShow;
    },
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    }
  },
};
</script>

<style scoped>
.btn-primary {
  border-radius: 0px;
  background-color: #7e5493;
  /* border-color: #7e5493; */
  padding-left: 20px;
  margin: auto;
  border: none;
}
::v-deep .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #7e54939f;
  border-color: #7e54939f;
}
</style>